.mobileapp-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 25vh;
  width: 200vh;
}
