.game6-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.game6-gamepage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.game6-gamepagechallenge {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.press-to-play {
  justify-content: center;
  align-items: center;
}


