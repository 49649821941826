.login-page {
  display: flex;
  flex-direction: row;
}

.login-col1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #6bffc6;
  z-index: 1;
  overflow-x: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
}

.login-col2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
  overflow-x: hidden;
  position: fixed;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
}

.login-card {
  width: 80%;
  height: 75%;
}

.login-cardcontent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.info-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.home2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: -5vh;
}

.home3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  height: 100vh;
  width: 100%;
}

.home4 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  height: 100vh;
  width: 100%;
}

.home5 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  height: 30vh;
  width: 100%;
}

.home6 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 25vh;
  width: 100%;
}

.title {
  text-align: center;
  color: black;
  font-weight: bold;
  font-size: 5vh;
}

.password {
  display: flex;
  flex-direction: row;
}

.navLogo {
  width: 5vh;
  height: 5vh;
  border-radius: 1vh;
  margin-right: 2vh;
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 40vh;
  height: 25vh;
  width: 200vh;
}

.footerLogo {
  width: 5vh;
  height: 5vh;
  border-radius: 1vh;
  margin-top: 2vh;
  margin-right: 2vh;
}

.logoicon {
  width: 20vh;
  height: 20vh;
  border-radius: 2vh;
}

.contactPic {
  width: 45vh;
  height: 30vh;
  box-shadow: inset 0 0 0 0;
}

.box3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50vh;
  height: 70vh;
  margin-right: 10vh;
}

.box4 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60vh;
  height: 70vh;
  margin-right: 10vh;
}

.box5 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60vh;
  height: 70vh;
  margin-left: 10vh;
}

.home3i {
  width: 75vh;
  height: 65vh;
  border-radius: 4vh;
  margin-right: 20vh;
  margin-top: 3vh;
  margin-bottom: 3vh;
}

.home4i {
  width: 75vh;
  height: 65vh;
  border-radius: 4vh;
}

Button {
  margin-top: 20vh;
}

body { 
  margin: 0;
  padding: 0;
}

.home-no-reg {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  position: relative;
  z-index: 1;
  height: 50vh;
  width: 100%;
}

.second, .third {
  opacity: 0;
}

.first, .second, .third {
  position: absolute;
}

.first {
  animation: first 10s infinite;
  background: linear-gradient(#d7fff3, #90ffd3);
  z-index: 10;
  height: 50vh;
  width: 100%;
}
  @keyframes first {
    0% {opacity: 1.0;}
    10% {opacity: 0.8;}
    20% {opacity: 0.6;}
    30% {opacity: 0.4;}
    40% {opacity: 0.2;}
    50% {opacity: 0.1;}
    60% {opacity: 0.2;}
    70% {opacity: 0.4;}
    80% {opacity: 0.6;}
    90% {opacity: 0.8;}
    100% {opacity: 1.0;}
  }
  .second {
    animation: second 10s infinite; animation-delay: 2s;
    background: linear-gradient(#40ffbf, #0fffa3);
    z-index: 20;
    height: 50vh;
    width: 100%;
  }
    @keyframes second {
      0% {opacity: 0;}
      10% {opacity: 0.2;}
      20% {opacity: 0.4;}
      30% {opacity: 0.6;}
      40% {opacity: 0.8;}
      50% {opacity: 1.0;}
      60% {opacity: 0.8;}
      70% {opacity: 0.6;}
      80% {opacity: 0.4;}
      90% {opacity: 0.2;}
      100% {opacity: 0;}
    }

  .third {
    animation: third 10s infinite;
    animation-delay: 8s;
    background: linear-gradient(#0beb7b, #00b95d);
    z-index: 30;
    height: 50vh;
    width: 100%;
  }
    @keyframes third {
      0% {opacity: 0;}
      10% {opacity: 0.2;}
      20% {opacity: 0.4;}
      30% {opacity: 0.6;}
      40% {opacity: 0.8;}
      50% {opacity: 1.0;}
      60% {opacity: 0.8;}
      70% {opacity: 0.6;}
      80% {opacity: 0.4;}
      90% {opacity: 0.2;}
      100% {opacity: 0;}
    }

/* .title {
  text-align: center;
  background-color: rgb(190, 164, 214);
  padding: 2vw 1vw;
  border-radius: 10px 10px 0 0;
  font-size: 2rem;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 30vw;
  padding: 2vw 1vw;
  background-color: rgb(250, 194, 194);
  border-radius: 0 0 10px 10px;
}

input {
  border: 1px solid rgb(172, 171, 171);
  border-radius: 10px;
  padding: 1vw 1vw;
  outline: none;
  margin: 5px;
} */
