.spg-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.spgRow1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 16vh;
}

.spgRow2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 16vh;
}

.footerSPG {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 25vh;
}

img {
  box-shadow: 0 0 8px 8px white inset;
}
