.game2-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.game2-gamepage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.game2-gamepagechallenge {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.game2-bar {
  display: flex;
  flex-wrap: nowrap;
  flex-grow: 1;
  width: 100%;
}
