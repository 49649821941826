.contact-page {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 5vh;
  width: 100%;
}

.contact-col1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 50vh;
}

.contact-col2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 35vh;
}

.contact-form {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 15vh;
  margin-bottom: 10vh;
  background-color: #6bffc6;
  border-radius: 4vh;
  height: 75vh;
  width: 80vh;
}

.footerContact {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 25vh;
  width: 100%;
}
