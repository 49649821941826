.profile-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.profile-not-logged {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 12vh;
}

.profile-logged {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.profile-profPic {
  width: 600;
  height: 600;
  box-shadow: inset 0 0 0 0;
  border-width: 2vh;
}

.profile-col1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.profile-col2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 20vh;
}

.profile-card {
  width: 90%;
  height: 80%;
  margin-top: 15vh;
  margin-left: 40vh;
}

.profile-cardcontent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.profile-icon {
  width: 30vh;
  height: 30vh;
  border-radius: 5vh;
  margin-top: 5vh;
  margin-bottom: 30vh;
}

.footerLogo {
  width: 5vh;
  height: 5vh;
  border-radius: 1vh;
  margin-top: 2vh;
  margin-right: 2vh;
}

.footerProf {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 25vh;
  width: 200vh;
}
