.forgotpassword-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.FP-card {
  width: 40%;
  height: 45%;
  margin-top: 2vh;
  margin-bottom: 5vh;
}

.FP-cardcontent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.registericon {
  width: 30vh;
  height: 30vh;
}
