/* src/Chatbot.css */

.chatbot-container {
    position: fixed;
    bottom: 20px;
    left: 20px;
    z-index: 1000;
}

.chatbot-bubble {
    background-color: #6BFFC6;
    color: black;
    padding: 15px 18px;
    border-radius: 30px;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.chatbot-overlay {
    position: fixed;
    bottom: 80px;
    left: 20px;
    width: 500px;
    height: 700px;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
}

.chatbot-header {
    background-color: #6BFFC6;
    color: white;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.close-chatbot {
    background: none;
    border: none;
    color: white;
    font-size: 16px;
    cursor: pointer;
}

.chatbot-body {
    flex: 1;
    padding: 0;
    overflow: hidden;
}

.chatbot-iframe {
    width: 100%;
    height: 100%;
    border: none;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}
