.createaccount-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.CA-card {
  width: 40%;
  height: 80%;
  margin-top: 5vh;
  margin-bottom: 5vh;
}

.CA-cardcontent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.registericon {
  width: 30vh;
  height: 30vh;
  box-shadow: inset 0 0 0 0;
}
