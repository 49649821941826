.game5-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.game5-gamepage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.game5-gamepagechallenge {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}


